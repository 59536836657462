.group-management_container {
    margin-left: 15px;

    .group-management_header {
        position: relative;
        font-weight: bold;
        text-align: center;
        font-size: 2.0rem;
        margin-bottom: 35px;

        & > button {
            position: absolute;
            left: -5px;
            top: -10px;
        }
    }

    .group-management_body {
        .card-controls {
            margin-top: 5px;
            display: flex;
            justify-content: space-between;
        }

        .ui .button {
            padding: 10px 15px;
        }
    }

    .group-management_add-card {
        height: 100%;
        min-height: 92px;
        font-size: 75px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgba(0, 0, 0, 0.7);
        padding-bottom: 20px;

        &:hover {
            cursor: pointer;
            z-index: 5;
            background: #fff;
            border: none;
            box-shadow: 0 1px 3px 0 #bcbdbd, 0 0 0 1px #d4d4d5;
            transform: translateY(-1px);
        }
    }
}

.loader {
    position: absolute;
    left: 55%;
    top: 50%;
}

.ui.cards {
    position: relative;
}

.ui.cards  div.ui.loader {
    position: absolute;
    font-size: 13px;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    color: rgba(0,0,0,.4);
}

.cards-trial-header {
    font-size: 20px;
    width: 100%;
}

.divider {
    height: 1px;
    width: 100%;
    background-color: #b0b0b0;
    margin-top: 25px;
    margin-bottom: 10px;
}

.cards-subsection {
    min-height: 50px;
    display: flex;
    flex-wrap: wrap;
}

.card-deleted {
    filter: brightness(0.85);
}

.card-trial-expired {
    background-color: #ed9393 !important;
}