.ui.input {
  margin-bottom: 15px;
}

.sso-btn-submit {
  margin: 10px;
}

.sso-btn-container {
  display: flex;
  justify-content: end;
  margin-bottom: 15px;
  align-self: end;
  margin-right: 20px;

  .ui.basic.button.sso-btn-cancel {
    box-shadow: none !important;
    color: black !important;
    margin-left: 20px;

    &:hover {
      background-color: #e1e0e0 !important;
    }

    &:active {
      background-color: transparent !important;
      box-shadow: 0 0 0 1px #db2828 inset !important;
    }
  }
}