.system-overload-popup {
  position: absolute;
  width: 450px;
  height: 110px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  left: 50%;
  transform: translateX(-50%);
  transition: 0.3s all ease-in-out;
  text-align: center;
  z-index: 1;
  background-color: lightgray;

  &.visible {
    top: -90px;
  }

  &.hidden {
    top: -260px;
  }

  &__top {
    position: relative;
    height: 32px;
    width: 100%;
    background-color: #303641;
  }

  &__close-button {
    position: absolute;
    background-color: #323b44;
    width: 16px;
    height: 16px;
    border: none;
    border-radius: 50%;
    top: 7px;
    right: 7px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    & > img {
      width: 100%;
    }

  }

  &__message {
    font-size: 14px;
    font-weight: 500;
    padding: 8px;
    padding-top: 5px;
    color: #323b44;
    line-height: 22px;

    & > a {
      font-weight: 700;
      color: black;
    }
  }
}