.sidebar {
    width: 250px;
}

.sidebar-link {
    padding: 10px;
    font-weight: 500;
    cursor: pointer;
}

.sidebar-link:hover {
    border-right: 5px solid dimgray;
    background-color: gainsboro;
}