.contract-spec {
  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;

    p {
      margin-bottom: 5px;
      font-weight: 400;
      font-size: 20px;
    }

    .events-count {
      &__container {
        display: flex;
        flex-direction: row;

        .tiers-table {
          width: 75%;
          margin: 20px;

        }

        .events-counter {
          margin: 20px;

          .ui.disabled.input {
            opacity: 0.9;
            height: 50px;
            width: 150px;

            input {
              font-size: 24px;
            }
          }
        }

      }
    }

    .detailed-events {
      &__container {
        display: flex;
        width: 100%;

        .detailed-events-table {
          width: 75%;
          margin: 20px;

          .controls {
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
  }
}

thead > tr > td {
  font-weight: bold;
  background-color: rgb(219, 226, 230);
}

.controls.no-data {
  margin: 20px;
}

.controls > .ui.button {
  background-color: lightgrey;
}