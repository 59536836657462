.sidebar-menu {
    width: 15%;
    min-width: 15%;
    background-color: #303641
}

.logo-header {
    width: 100%;
    padding: 25px;
}

.ui.grid {
    margin-left: 0px !important;
}

.sub {
    margin-left: 30px !important;
    transition: 3s ease-in-out;
}

.logo {
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 70px;
}

.menu-item {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    list-style-type: none;
    position: relative;
    border-bottom: 1px solid rgba(69, 74, 84, 0.7);
    color: white;
    font-size: 1.1rem;
    font-weight: 400;
    margin: 10px;
    margin-left: 15px;

    & p {
        margin: 5px;
    }

    & i {
        margin: 5px;
    }
}

.main-content {
    background-color: #F1F1F1;
    width: 100%;
    padding-right: 15px;
    max-width: 1617px;
}

.main-menu {
    padding-inline-start: 0px;
}

.logo-wrapper {
    height: 100%;
    width: 100%;
}