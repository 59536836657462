.select-label {
    font-weight: 500;
    font-size: 18px;
    margin: 15px;
    margin-left: 0px;
}

.restrictions-modal_selects {
    & .css-xb97g8 svg {
        display: none !important;
    }
}