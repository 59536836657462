.chart-container {
  margin-top: 30px;
  padding-bottom: 10px;
}

.multi-chart-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
