.logo-container {
    display: flex;
    justify-content: center;

    img {
        max-height: 100px;
    }
}

.fileContainer {
    p {
        display: none
    }
}

.group-modal__buttons {
    & > button {
        width: 85px;
    }
}

.ui.red.basic.button.cancel-button {
    box-shadow: none !important;
    color: black !important;

    &:hover {
        background-color: #e1e0e0 !important;
    }

    &:active {
        background-color: transparent !important;
        box-shadow: 0 0 0 1px #db2828 inset !important;
    }
}

.ui.attached.tabular.menu {
    & > a {
        cursor: default;
    }
}