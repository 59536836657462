.restriction-tab__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.trial-warning {
    line-height: 16px;
    font-size: 12px;
    color: #c92626;
}

.separate-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
    padding-left: 30px;
    padding-right: 30px;
    border-bottom: 1px solid #d3d3d3;
    margin-top: 15px;

    &:last-child {
        border: none;
    }

    & > div {
        width: 50%;
    }

    &.streams-section {
        flex-direction: row;
        padding-bottom: 15px;
    }

    &.download-section {
        height: 80px;
    }

    &.domains-section {
        height: 80px;
    }

    &.rest-section {
        height: 80px;
    }

    &.categories-section {
        display: flex;
        flex-direction: row;

        & > div {
            width: 50%;
        }
    }
}




