.table-container {
    tbody {
        button {
            width: 50px;
        }
    }

    & .users-table-container {
        margin: 15px;
        width: 80vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .user-table_remaining-label {
            font-weight: bold;
            font-size: 20px;
            margin: 10px 0;
        }
    }

    .four.wide {
        width: 250px !important;
        min-width: 250px !important
    }

    .three.wide {
        width: 200px !important;
        min-width: 200px !important;

        &.action-buttons {
            display: flex;
            justify-content: center;
            background: white;
            min-width: 220px !important
        }
    }

    .one.wide {
        width: 110px !important;
        min-width: 110px !important
    }

}

td {
    text-align: center !important;
}

.user-table_error {
    font-size: 16px;
    color: red;
    font-weight: 500;
}

.ui.pagination.menu {
    margin-bottom: 20px;
}

.ui.search>.results {
    display: none !important
}

.ui.search {
    width: 50%;
}

.ui.input {
    width: 100%;
}

.loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.country-flag {
    width: 20px;
    height: 13px;
    margin-right: 3px;
}

tr td .css-1wy0on6 {
    display: none !important;
}

001 tr td {
    background-color: white;
}


.dropdown-container {
    min-width: 170px;
}

.ui.celled.table.competitions-table {
    width: 80vw !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

.username-email-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ui.celled.table tr th.additional-border {
    border: 1px solid rgba(34, 36, 38, .1);
}

.ui.celled.table tr th.unnecessary-border {
    border-left: none;
}

.vertical-direction th p {
    transform: rotate(45deg);
    margin-top: 7px;
    margin-bottom: 7px;
}

.sorting-button {
    cursor: pointer !important;

    &:hover {
        background-color: #c7c7c7 !important;
    }
}

.action-buttons {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

i.icon.delete:before {
    margin-right: 2px;
}

i.icon.spinner:before {
    margin-right: 2px;
}

.users-table-buttons {
    display: flex;
}

.remove-devices-icon {
    width: 20px;
    height: 20px;
}