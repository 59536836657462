.btn-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.ui.search {
  width: 100% !important
}

.sso-table > tbody > tr > td {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.sso-table-wrapper {
  text-align: center;
  padding-bottom: 25px;
}