.ui.grid {
    height: 100%;
    margin-left: 5rem !important;
}

.table-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.container {
    height: 100%
}

.user-page {
    height: 100%;
}