i.arrow {
    cursor: pointer;
    margin-left: 10px;
}

.ui.table thead th {
    text-align: center;
}

.ui.definition.table thead:not(.full-width) th:first-child {
    box-shadow: none !important;
}

.download-csv {
    width: 100px;
}

.download-container {
    text-align: right;
    font-weight: 500;
    color: rgb(48, 54, 65);
    margin-right: 15px;
}

.statistics-general-table-container {
    display: flex;
    flex-direction: column;
    margin-left: 15px;

h1 {
    text-align: center;
}

.controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

.ui.fluid.multiple.selection.dropdown {
    width: auto;
}
}

.empty {
    text-align: center;
    font-size: 24px;
}
}

.button-container {
    align-self: center;

    & > .ui.button {
        background-color: #ABBBC4;
    }
}

.statistics-table-wrapper {
    margin-top: 20px;
    width: 100%;
    border: none;
}