.sidebar-container {
    min-height: calc(100vh - 95px);
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.sidebar-icon {
    padding-top: 7px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.animation-container {
    width: 250px;
}