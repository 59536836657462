.user-modal_container {
    .field {
        width: 200px !important
    }

    .ui.dropdown .menu>.item>.image,
    .ui.dropdown .menu>.item>img,
    .ui.dropdown>.text>.image,
    .ui.dropdown>.text>img {
        display: inline-block;
        vertical-align: top;
        max-width: 20px !important;
        height: auto;
        border-radius: 0;
        margin-top: 5px;
        margin-bottom: 5px;
        margin-right: 5px;
    }
}