.ui.grid.header {
    justify-content: space-between;
    height: 80px;
    margin-left: 0 !important;
}

.icon-wrapper {
    text-align: end;
    align-items: center;
}

.main-header {
    height: 95px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 45px;
    margin: 0 15px 0 15px;
}

.user-info {
    display: flex;
    justify-content: flex-start;
    color: #303641;
    font-size: 20px;

    & p {
        margin-left: 10px;
    }
}

.actions {
    display: flex;
    justify-content: flex-end;
    font-size: 20px;
    color: #303641;
    margin-right: 25px;

    & i {
        color: #303641;
        margin-right: 7px;
        margin-left: 7px;
    }
}

.logout-link,
.inplay-link {
    margin-right: 25px;
    cursor: pointer;
}