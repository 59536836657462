
.announcement-modal {
  width: 80%;
  margin: 0 auto;

  &__header {
    font-size: 24px;
    margin-top: 10px;
    text-align: center;
  }

  &__form {
    margin: 10px auto;
    width: 800px;
    font-size: 14px;
    background-color: white;
    border-radius: 4px;

    & label {
      width: 100%;
      font-weight: bold;
      display: block;
      margin: 20px 0 5px 0;
    }

    &-input {
      width: 100%;
      height: 40px;
      display: block;
      padding-left: 12px;
      font-weight: normal;
      border: 1px solid lightgrey;
      border-radius: 4px;
    }

    &-editor {
      & > div:first-child {
        border-radius: 4px 4px 0 0;
      }
      & > div:nth-child(2) {
        position: relative;
        border-radius: 0 0 4px 4px;
        min-height: 90px;
        max-height: 350px;
        overflow: auto;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    &-tip {
      margin-top: 5px;
      font-size: 12px;
    }

    div.ql-tooltip, .ql-tooltip.ql-editing {
      position: absolute !important;
      top: 5px !important;
      left: 15px !important;
    }

    &-time {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 10px;

      & > label {
        width: 30%;
      }

      .SingleDatePicker {
        margin: 12px 0 0 20px;

        & div, input {
          border-radius: 4px;
        }

        .SingleDatePickerInput {

          .DateInput {
            display: block !important;
            border: 1px solid lightgrey;
          }
        }
      }
    }
  }
}

div.actions {
  margin-right: 0;
}

.ui.button.cancel-button {
  background-color: transparent;
  color: black;

  &:hover {
    background-color: lightgrey;
  }
}

