.charts-page{
  width: 100%;
  display: flex;
  justify-content: flex-start;

  .json-placeholder {
    background-color: #F1F1F1;
    color: green
  }

  code {
    text-align: left;

    pre {
      max-height: 150px;
      overflow-y: scroll;
    }
  }

  &__controls-wrapper {
    width: 1030px;
    position: relative;
    text-align: center;
    margin-right: 10px;
    margin-left: 15px;

    & > .ui.button {
      width: 285px;
      position: absolute;
      background-color: lightgrey;
      right: 27px;
      top: 120px;
    }
  }

  &__controls {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;

    & > div:first-child {
      padding-top: 4px;
      width: 530px;
      margin-left: 15px;
    }

    & > div:nth-child(2) {
      width: 300px;
      margin-left: 20px;
    }

    & > div:nth-child(2) > button.ui.button {
      width: 285px;
      margin-top: 8px;
      background-color: lightgrey;

      &:hover {
        background-color: #a1a1a1;
      }
    }

    .charts-page__buttons > button.ui.button {
        width: 125px;
    }

    ul {
      padding-bottom: 3px;
    }

    .css-2b097c-container * {
      z-index: 100;
    }
  }

  &__buttons {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
  }

  .css-2b097c-container {
    width: 22%;
  }
}

.goog-tooltip>div {
  background-color: rgba(248, 248, 248, 0.75) !important;
}

@media (max-width: 1550px) {
    .charts-page {
      &__controls-wrapper {
        width: 750px;
      }
      &__controls {
        & > div {
          &:first-child {
            width: 360px;
          }
        }
        .charts-page__buttons > button.ui.button {
          font-size: 12px;
          padding: 7px 6px;
        }
      }
    }
  }
