.remove-announcement-buttons {
  display: flex;

  & > .button.ui.button {
    width: 94px !important;

    &:nth-child(2) {
      border: none;
      background-color: transparent;
      color: black;

      &:hover {
        background-color: #e1e0e0 !important;
      }

      &:active {
        background-color: transparent !important;
        box-shadow: 0 0 0 1px #db2828 inset !important;
      }
    }
  }
}
